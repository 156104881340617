import React from 'react';

import { NumericFormat } from 'react-number-format';

type IPropsInputCurrency = {
  err?: boolean;
  placeholder?: string;
  decimalScale?: number;
};

export const InputCurrency: React.FC<IPropsInputCurrency> = ({ err, decimalScale, ...rest }) => {
  const renderClass = () => {
    if (err === undefined) return '';
    if (err) {
      return 'error';
    } else {
      return 'success';
    }
  };

  return (
    <NumericFormat
      {...rest}
      allowLeadingZeros
      thousandSeparator=","
      decimalScale={decimalScale}
      className={`input-number ${renderClass()}`}
    />
  );
};

export default InputCurrency;
